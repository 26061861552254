<template>
  <div class="bigbox">
    <!-- 智慧采购 -->
    <div class="zhfwtop">
      <div class="indeximgtopcontent">
         <div class="indexlogo"></div>
        <div class="indexlist">
          <ul class="indexlistul">
            <li  @click="go('/home')"><span>首页</span></li>
            <li  class="indexli2">
              <span class="active2">产品解决方案</span>
                <ul class="menu">
                  <li  @click="go('/cpjjfa/zhfw')">智慧法务</li>
                  <span class="active" @click="go('/cpjjfa/zhcg')">智慧采购</span>
                  <li  @click="go('/cpjjfa/zhrz')">智慧人资</li>
                  <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                  <li  @click="go('/cpjjfa/bgxt')">办公协同</li>
                  <li @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                </ul>
            </li>
            <li @click="go('/jszc')"><span>技术支持</span></li>
            <li @click="go('/yhal')"><span>用户案例</span></li>
            <li  @click="go('/gywm')"><span>关于我们</span></li>
          </ul>
      </div>
      <a href="http://www.kingislucky.cn:81/#/home" class="enbutton">
        <img src="../.././assets/enicon.png" alt="">
        <div>Translate</div>
      </a>
    </div>
    </div>
    <!-- 行业痛点 -->
    <div class="hytd">
      <div class="hytdcontent">
        <div class="hytdcontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">行业痛点</div>
          <div class="hxystitleback2"></div>
        </div>
        <div class="hytdpng"></div>
      </div>
    </div>
    <!-- 数字化转型 -->
    <div class="szhzx">
      <div class="szhzxcontent">
        <div class="szhzxcontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">数字化转型</div>
          <div class="hxystitleback2"></div>
        </div>
        <div class="szhzxpng"></div>
      </div>
    </div>
    <!-- 业务关注点 -->
    <div class="ywgzd">
      <div class="ywgzdcontent">
        <div class="ywgzdcontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">业务关注点</div>
          <div class="hxystitleback2"></div>
        </div>
        <div class="ywgzdpng"></div>
      </div>
    </div>
    <!-- 产品方案 -->
    <div class="cpfa">
      <div class="cpfacontent">
        <div class="cpfacontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">产品方案</div>
          <div class="hxystitleback2"></div>
        </div>
        <div class="cpfapng">
          <div><img src="../.././assets/zhcg_cpfapng1.png" alt=""></div>
          <div><img src="../.././assets/zhcg_cpfapng2.png" alt=""></div>
          <div><img src="../.././assets/zhcg_cpfapng3.png" alt=""></div>
          <div><img src="../.././assets/zhcg_cpfapng4.png" alt=""></div>
        </div>
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="cpys">
      <div class="cpyscontent">
        <div class="cpyscontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">行业痛点</div>
          <div class="hxystitleback2"></div>
        </div>
        <div class="cpyscontenttitleinduce">全流程：提供企业采购管理解决方案，覆盖企业采购管理全流程。</div>
        <div class="cpyspng"></div>
      </div>
    </div>
    <!-- 咨询热线 -->
    <!-- <zi-xun></zi-xun> -->
    <!-- 版权 -->
    <tabar-bottom></tabar-bottom>
  </div>
</template>

<script>
import TabarBottom from '../../components/TabarBottom.vue'
import ZiXun from '../../components/ZiXun.vue'
  export default {
  components: { ZiXun, TabarBottom },
    name:'zhcg',//智慧采购
    data(){
      return{

      }
    },
    methods:{
       go(path){
        this.$router.push({
          path:path
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.enbutton{
  cursor: pointer;
  position: absolute;
  z-index: 9999;
  right: 5%;
  top: 42px;
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  width: 102px;
  height: 28px;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}
.indeximgtopcontent{
  width: 1223px;
  height: 46px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}
// 产品优势
.cpys{
  // width: 1903px;
  height: 1002px;
  margin: 0 auto;
  background: #FFFFFF;
  .cpyscontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
     .cpyscontenttitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 88px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.cpyscontenttitleinduce{
  text-align: center;
  width: 788px;
  height: 16px;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #858B95;
  margin: 0 auto;
  margin-bottom: 88px;
}
.cpyspng{
  width: 1200px;
  height: 645px;
  background: url('../.././assets/zhcg_cpyspng.png') no-repeat center center;
  background-size: 1200px 644.25px;
}
  }
}
// 产品方案
.cpfa{
  // width: 1903px;
  height: 664px;
  background: #F8F8F8;
  margin: 0 auto;
  .cpfacontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
            .cpfacontenttitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  padding-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.cpfapng{
  width: 1200px;
  height: 360px;
  display: flex;
}
  }
}
// 业务关注点
.ywgzd{
  // width: 1903px;
  height: 608px;
  margin: 0 auto;
  .ywgzdcontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
      .ywgzdcontenttitle{
  width: 455px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.ywgzdpng{
  width: 1200px;
  height: 305px;
  background: url('../.././assets/zhfa_ywgzdpng.png') no-repeat center center;
  background-size: 1200px 305px;
}
  }
}
// 数字化转型
.szhzx{
  // width: 1903px;
  height: 758px;
  margin: 0 auto;
  background: url('../.././assets/zhcg_szhzxback.png') no-repeat center;
  background-size: 100%;
  .szhzxcontent{
    width: 1200px;
    height: inherit;

    margin: 0 auto;
          .szhzxcontenttitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  padding-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.szhzxpng{
  width: 1200px;
  height: 456px;
  background: url('../.././assets/zhcg_szhzxpng.png') no-repeat center center;
  background-size:1200px 456px;
}
  }
}
// 行业痛点
.hytd{
  // width: 1903px;
  height: 804px;
  margin: 0 auto;
  .hytdcontent{
    width: 1200px;
    height: inherit;

    margin: 0 auto;
        .hytdcontenttitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.hytdpng{
  width: 1200px;
  height: 502px;
  background: url('../.././assets/zhcg_hytd.png') no-repeat center;
  background-size: 1200px 502px;
}
  }
}
//
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
}
.active2{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.zhfwtop{
  // width: 1903px;
  height: 500px;
  background: url('../.././assets/zhcgback.png') no-repeat center 0;
  margin: 0 auto;
   padding-top: 34px;
  .indexlogo{

  width: 220px;
  height: 52px;
  background: url('../.././assets/logo.png') no-repeat center center;
}
.zhfwbutton{
  width: 373px;
  height: 175px;

}
.zhfwindc{
  text-align: center;
  float: left;
  width: 1100px;
  height: 51px;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 432px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.indexlist{
   width: 632px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  .indexlistul{
    display: flex;
    text-align: center;
    li{
      cursor: pointer;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
}
//
.menu{
  position: absolute;
  display: none;
  top: 45px;
  background: #fff;
  color: #282A2E;
  padding-left: 20px;
  left: 16px;
  text-align: left;
}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
}
</style>
