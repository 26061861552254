<template>
  <div>
    <div class="zxrx">
      <div class="zxrxtitle">诚邀您咨询体验</div>
      <div class="zxrxphone">服务热线：18762548573</div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
  // 咨询热线
.zxrx{
  // width: 1903px;
  height: 120px; 
  margin: 0 auto;
  background: #FFA000;
  display: flex;
  justify-content: center;
  align-items: center;
 
}
 .zxrxtitle{
    padding-right: 20px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF; 
  }
  .zxrxphone{
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF; 
  }  
</style>